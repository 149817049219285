.dropdown-notifications-list-container {
  width: 420px;
  max-height: 385px;
  overflow-y: scroll;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  .ant-list-item {
    height: 90px;
    font-weight: 500 !important;
  }
}
.dropdown-notifications-list-container::-webkit-scrollbar {
  display: none;
}

.notification-item,
.label-notifications {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #777777;
  border-bottom: 1px solid #dddddd !important;
}

.unreadNotification {
  color: #222222;
  background: rgba(158, 150, 242, 0.17);
}

.see-more {
  height: 25px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center !important;
  color: #777777;
  background: rgba(158, 150, 242, 0.17);
}

@primary-color: #9E96F2;