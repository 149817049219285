.button-basic {
  padding: 6px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;

  & .ant-image {
    margin: 0 5px;
  }

  svg {
    margin-right: 5px;
  }
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #aca4ff;
  border-color: #9e96f2;
}

.ant-btn-rtl {
  svg {
    margin: 0;
    margin-left: 5px;
  }
}

.black {
  color: black;
}

.blue {
  color: #fff;
  background: #2d9cdb;
  border: 1px solid #2d9cdb;
}
.blue:hover {
  color: #2d9cdb;
  background: #fff;
  border: 1px solid #2d9cdb;
}
.blue:focus {
  color: #fff;
  background: #0c9bbb;
  border: 1px solid #0c9bbb;
}

.green {
  color: #fff;
  background: #27ae60;
  border: 1px solid #27ae60;
}
.green:hover {
  color: #27ae60;
  background: #fff;
  border: 1px solid #27ae60;
}
.green:focus {
  color: #fff;
  background: #27ae60;
  border: 1px solid #27ae60;
}

@primary-color: #9E96F2;